import React from "react";

function BuyMeACoffeeButton() {
    return (
        <a href="https://www.buymeacoffee.com/learn_french_reels">
            <img
                className="bmc-button-img"
                alt="Buy Me a Coffee Widget"
                src="/images/bmc-button.png"
            />
        </a>
    );
}

export default BuyMeACoffeeButton;