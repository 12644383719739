// Import necessary modules and components at the top
import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef, useEffect } from "react";
import { Analytics } from '@vercel/analytics/react';
import Hotjar from '@hotjar/browser'; // Import Hotjar
import { SpeedInsights } from "@vercel/speed-insights/next"

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  useEffect(() => {
    // Initialize Hotjar here, ensuring it only runs client-side
    const siteId = 3920336;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
      <>
        <Meta title="My French" />

        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <Analytics />
              <SpeedInsights />
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (
                    <Component {...pageProps} />
                ) : (
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                )}
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
      </>
  );
}

export default MyApp;
