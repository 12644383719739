import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
      <div>
          <Head>
              <title> My French | Learn French | Free Online French Lessons</title>
              <link rel="icon" href="/favicon.png"/>
              <meta name="description" content={desc}/>
              <meta name="keyword" content={keyword}/>
              <meta name="google-adsense-account" content="ca-pub-8097312950095161"/>
          </Head>
      </div>
  );
};

Meta.defaultProps = {
    title: "My French | Learn French | Free Online French Lessons",
  keyword:
      "Learn French, Mobile App, French Lessons, Interactive Lessons, Practical Exercises, Audio Resources, Language Progression, Learning Companion, Beginners French, French Mastery, Foreign Language, Self-learning, Education App, Language Practice, Spoken French, Educational Application, French Language Proficiency, French Vocabulary, French Grammar, French Pronunciation",
  desc: "Want to learn French effectively and enjoyably? Our mobile app for learning French is your ideal companion! Whether you're a beginner or looking to perfect your language skills, our app offers interactive lessons, practical exercises, and audio resources to help you master this beautiful language. With a user-friendly interface and progress tracking features, you can learn French wherever and whenever you want. Download our app now to start your journey to French proficiency!",
};

export default Meta;
