import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";


const firebaseConfigFirst = {
    apiKey: "AIzaSyDfpPV9x1JfmCpooKjaHPbp917d6judU9w",
    authDomain: "myfrench-d6af4.firebaseapp.com",
    databaseURL: "https://myfrench-d6af4-default-rtdb.firebaseio.com",
    projectId: "myfrench-d6af4",
    storageBucket: "myfrench-d6af4.appspot.com",
    messagingSenderId: "1097977816908",
    appId: "1:1097977816908:web:ef160afea9b02a87ef51e9",
    measurementId: "G-XWBLKKJ0KL"};

// Configuration du deuxième projet Firebase
const firebaseConfigSecond = {
    apiKey: "AIzaSyDi583zCb7ETO7jIi8HvCycmuSC2rWMNN8",
    authDomain: "frenchlearn-a403f.firebaseapp.com",
    databaseURL: "https://frenchlearn-a403f-default-rtdb.firebaseio.com",
    projectId: "frenchlearn-a403f",
    storageBucket: "frenchlearn-a403f.appspot.com",
    messagingSenderId: "741431566495",
    appId: "1:741431566495:web:a865c926e2c6d32f77c2aa",
    measurementId: "G-82SHZDZFWS"
};

export const appFirst = initializeApp(firebaseConfigFirst);
export const dbFirst = getFirestore(appFirst);

export const appSecond = initializeApp(firebaseConfigSecond, "second");
export const dbSecond = getFirestore(appSecond);

